import styles from "./Home.module.css"

//import BannerFundo from '../assets/banner/Fundo-Imagem-Principal-Site.gif'
import BannerContainer from '../assets/img/BannerPrincipal-remove-fundo.png';
import Sobrenos from '../assets/img/Sobrenos-removebg-preview.png';

//Ícones
import Design from '../assets/icons/UX-responsivo.gif';
import MarketingDigital from '../assets/icons/Marketing-megafone.gif';
import DesignResponsivo from '../assets/icons/Design-responsivo.gif';
import DesingGrafico from '../assets/icons/design-grafico.gif';
import LogoDesing from '../assets/icons/Logo-ideia.gif';
import WebSiteDesing from '../assets/icons/Designer-de-web.gif';
import SeoEficient from '../assets/icons/Seo-Local.gif';
import LoadingRapido from '../assets/icons/Carregando.gif';
//import NosdiferenciaPrincipal from '../assets/img/Nos-diferencia-ilustração-remove-fundo.png';
//import NosDiferencia from '../assets/img/O que nos diferencia 230x230.png';

//Nossa missão
//import NossaMissao from '../assets/img/NossaMissão1-removed-fundo.png';
import NossaMissao from '../assets/img/NossaMissão-remove-fundo.png';


function Home () {


    return(
        <>
        {/* <main>
        <div className={styles.banner}>
        <div className={styles.BannerPrincipal}>
        <img src={BannerFundo} alt="Banner Fundo" />        
        </div>
        <div className={styles.bannerdescricao}>
            <div className={styles.descricao}>
            <h2>Pronto para elevar seu negócio com um Web Design de ponta?<span>VAMOS INICIAR O SEU PROJETO AGORA</span>e transform​ar suas ideias em realidade?</h2>
            <button>VAMOS COMEÇAR?</button>
            </div>
        <img src={BannerPrincipal} alt="Banner Principal" />
        </div>
        </div>
        
        </main> */}

        <main id="bannerareas" className={styles.textareasbanner}>
            {/* <img src={BannerFundo} alt="Banner Fundo" /> */ }
            <div id="banner-informacao" className={styles.textcontainer}>
                <h3 className={styles.secaotituto}>
                Pronto para elevar seu negócio com um Web Design de ponta?<span> VAMOS INICIAR O SEU PROJETO AGORA</span> e transform​ar suas ideias em realidade?
                </h3>
                <a href="/#" className={styles.btn}>VAMOS COMEÇAR?</a>
            </div>
            <div id="imagebannercontainer" className={styles.imagebannercontainer}>
                <img src={BannerContainer} alt="Banner Container" />
            </div>
        </main>
        <section>
            <div className={styles.sectionsobrenos}>
                <div className={styles.imgsobrenos} >
                    <img src={Sobrenos} alt="Sobre Nós" />
                </div>
                <div className={styles.descricaosobrenos}>
                    <h3>Sobre nós</h3>
                    <p>Somos especialistas em Desenvolvimento Web Designer e design gráfico moderno e responsivo, transcende a estética pura, integrando-se perfeitamente com a mais recente tecnologia para potencializar o Website da sua empresa. Ao harmonizar visuais impressionantes com funcionalidade adaptável, asseguramos que seu site não só pareça excepcional em qualquer dispositivo, mas também ofereça uma experiência de usuário fluida e intuitiva. Esta abordagem estratégica visa não apenas capturar a atenção do seu público, mas também facilitar a interação e conversão, transformando visitantes em clientes. Assim, utilizamos o design gráfico como uma ferramenta poderosa para melhorar significativamente a presença online da sua empresa, garantindo que ela se destaque em um mercado digital competitivo.</p>
                </div>
            </div>
        </section>
        <section className={styles.nossosservicos}>
            <div>
                <h1>Visão Geral dos nossos Serviços</h1>
            </div>
            <div className={styles.servicoscontainer}>            
            <div className={styles.servicos}>
                <img src={Design} alt="UX Responsivo" />
                <h3>UI/UX Design</h3>
            </div>
            <div className={styles.servicos}>
                <img src={MarketingDigital} alt="Marketing Digital" />
                <h3>Marketing Digital</h3>
            </div>
            <div className={styles.servicos}>
                <img src={DesignResponsivo} alt="Design Responsivo" />
                <h3>Design Responsivo</h3>
            </div>
            <div className={styles.servicos}>
                <img src={DesingGrafico} alt="Desing Grafico" />
                <h3>Desing Gráfico</h3>
            </div>
            <div className={styles.servicos}>
                <img src={LogoDesing} alt="Logo Desing" />
                <h3>Logo Desing</h3>
            </div>
            <div className={styles.servicos}>
                <img src={WebSiteDesing} alt="Web Site Desing" />
                <h3>Web Site Desing</h3>
            </div>
            <div className={styles.servicos}>
                <img src={SeoEficient} alt="Seo" />
                <h3>SEO Eficiente</h3>
            </div>
            <div className={styles.servicos}>
                <img src={LoadingRapido} alt="Loading Rapido" />
                <h3>Loading Rapido</h3>
            </div>
            </div>
        </section>

        
        <section className={styles.nossamissaocontainer}>
            <div className={styles.ciculoaberto}>
                
            </div>
            <div className={styles.ciculoprincipal}>
                <img src={NossaMissao} alt="Nossa Missão" />
            </div>
            <div className={styles.ciculofechado}>

            </div>
            <div className={styles.informacao}>
                <h3>Nossa missão é harmonizar o design humano com a tecnologia digital.</h3>
                <p>Nossa abordagem é única. Acreditamos que cada projeto é uma nova oportunidade para inovar, impressionar e estabelecer novos padrões. Combinamos tecnologia de ponta, design intuitivo e estratégias de marketing digital para criar sites que são visualmente impressionantes, extremamente funcionais e otimizados para conversões.</p>
                <a href="/#" className={styles.btn}>VAMOS COMEÇAR?</a>
            </div>
            <div className={styles.ciculoabertopequeno}>

            </div>
        </section>

        <section >                        
            <div className={styles.visaodetalhadacontainer}>                
                <h1>Visão Detalhada dos nossos Serviços</h1>            
            <div className={styles.visaodetalhada}>                            
            <div className={styles.detalheservicos}>
                <img src={Design} alt="UX Responsivo" />            
                <h3>UI/UX Design</h3>
                <p>Aplicamos as melhores práticas de UX/UI para garantir que seus visitantes se tornem futuros clientes.</p>
            </div>            
            <div className={styles.detalheservicos}>
                <img src={MarketingDigital} alt="Marketing Digital" />
                <h3 id="detalheservicos">Marketing Digital</h3>
                <p>Criamos sites que não são apenas uma vitrine, mas uma peça ativa na sua estratégia de marketing digital, impulsionando vendas e aumentando a visibilidade da sua marca.</p>                
            </div>
            <div className={styles.detalheservicos}>
                <img src={DesignResponsivo} alt="Design Responsivo" />
                <h3>Design Responsivo</h3>
                <p>Sites que se adaptam perfeitamente a qualquer tamanho de tela, proporcionando uma experiência de usuário sem falhas, seja no desktop, tablet ou celular.</p>
            </div>
            <div className={styles.detalheservicos}>
                <img src={DesingGrafico} alt="Desing Grafico" />
                <h3>Desing Gráfico</h3>
                <p>Combinamos estética com funcionalidade para garantir que cada peça de design não apenas pareça excelente, mas também reforce sua identidade de marca e objetivos de comunicação.</p>
            </div>
            <div className={styles.detalheservicos}>
                <img src={LogoDesing} alt="Logo Desing" />
                <h3>Logo Desing</h3>
                <p>Um logo bem projetado é imediatamente reconhecível, inspira confiança e cria uma conexão duradoura com seu público.</p>
            </div>
            <div className={styles.detalheservicos}>
                <img src={WebSiteDesing} alt="Web Site Desing" />
                <h3>Web Site Desing</h3>
                <p>Soluções que refletem a essência da sua marca, atendendo às suas necessidades específicas e aos objetivos do seu negócio.</p>
            </div>
            <div className={styles.detalheservicos}>
                <img src={SeoEficient} alt="Seo" />
                <h3>SEO Eficiente</h3>
                <p>Melhoramos a visibilidade do seu site nos motores de busca, atraindo mais tráfego qualificado e aumentando suas oportunidades de venda.</p>
            </div>
            <div className={styles.detalheservicos}>
                <img src={LoadingRapido} alt="Loading Rapido" />
                <h3>Loading Rapido</h3>
                <p>Otimizamos cada aspecto do seu site para garantir tempos de carregamento rápidos, mantendo seus clientes felizes e engajados desde o primeiro clique.</p>
                </div>
                </div>  
                </div>                                 
        </section>

        <section className={styles.oquenosdiferenciaocontainer}>
            <div className={styles.ciculoabertodiferencia}>
                
            </div>
            <div className={styles.ciculoprincipaldiferencia}>
            {/* <img src={NosdiferenciaPrincipal} alt="O que nos diferencia estrelas" /> */}    
            </div>
            <div className={styles.ciculofechadodiferencia}>

            </div>
            <div className={styles.ciculocifraodiferencia}>

            </div>
            <div className={styles.informacaonosdiferencia}>
                <h3>O Que nos Diferencia?</h3>
                <p>Não somos apenas designers; somos arquitetos digitais, construindo a fundação para o seu sucesso online. Acreditamos que cada pixel tem um propósito e cada interação conta uma história. Ao escolher nossos serviços, você está optando por mais do que um site bonito; está escolhendo uma parceria dedicada a transformar visões em uma presença online vibrante e eficaz.</p>
                <a href="/#" className={styles.btn}>VAMOS COMEÇAR?</a>
            </div>
            <div className={styles.ciculoabertopequenodiferencia}>

            </div>
        </section>
        
        <section className={styles.portifoliocontainer}>

        </section>
        
        </>
        
    )
}

export default Home;