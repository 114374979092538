import styles from './header.module.css';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/Logo Desenvolvedor de site Branco.png'
//import BannerFundo from '../assets/banner/Fundo-Imagem-Principal-Site.gif'

function Header(){

    return(
        <>
        <header className={styles.navbar}>            
            <div>
            <img className={styles.logo} src={Logo} alt='Logo do Site' />        
            </div>
            <div className={styles.link}>
            <Link to={"/#"}>Sobre nós</Link>
            <Link to={"/#"}>Serviços</Link>
            <Link to={"/#"}>Portfólis</Link>
            <Link to={"/#"}>Contato</Link>
            </div>
        </header>
        <main className={styles.main}>

        </main>          
        </>
    )
}

export default Header;